import React, { useEffect, useState,useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import {FcPrevious,FcNext} from 'react-icons/fc'
import Slider from 'react-slick';
import Card from "../components/card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./stye.css"

import { Footer, Navbar } from "../components";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  





  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setLoading2(true);
      const response = await fetch(` ../assets/ajax.php?products=${id}`);
      
      const data = await response.json();
      setProduct(data);
      setLoading(false);
      
      const response2 =  await axios.create({
        withCredentials: true,
      });
      response2.get("../assets/ajax.php?show")
      .then((resp) =>  {
      setSimilarProducts(resp.data);
      
      setLoading2(false);
    });
    
  };getProduct() },[]);

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };
const Items =(pro)=>{

  const [valsize,setvalsize]=useState(null);
  const[valcolor,setvalclor]=useState(null);

const dispatch = useDispatch();

const addProduct = (product) => {
  if(valsize!==null&&valcolor!=null){ 
  dispatch(addCart(product))}
}
const handleSubmit=(event)=>{
  event.preventDefault();
console.log(valcolor)
console.log(valsize)
}
  return(

    


<div className="d-flex justify-content-between  w-100 card-dit  " dir="ltr" style={{overflowX:"hidden"}} >
<div className="over" id="over w-100 " dir="rtl">
        <img
          className="img-fl my-2 border border-3 rounded-3"
          src={pro.src}
         width="100%"
        
        
        />
    
    </div>
  
    <form onSubmit={handleSubmit} >  
   <div className="prosel   w-100 " style={{width:"100%",overflow:"hidden"}} >
      <div
      className="my-4">  <p>{pro.it.title}</p></div>
          
              <div></div>  
                <div className="size d-flex">
                  
                    <div className="my-2">
                    <label>
			<input type="radio" name="radios" value="option1" onChange={(event)=>{setvalsize(event.target.value)}}  required/>
      <span>7</span>
		</label>
                    
    <label>
			<input type="radio" name="radios" value="option2"  onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>8</span>
		</label>
    <label>
			<input type="radio" name="radios" value="option1" onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>9</span>
		</label>
    <label>
			<input type="radio" name="radios" value="option1"  onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>7</span>
		</label>
    </div><div>
                    <h2>: الحجم</h2>
                    </div>
                </div>

                <div className="color d-flex my-4 ">
                   
                    <div className="m-2">
               
		<label>
			<input type="radio" name="radio"  required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"black"}}></span>
		</label>
		<label>
			<input type="radio" name="radio" required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"red"}}></span>
		</label>
		<label>
			<input type="radio" name="radio" required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"white"}}></span>
		</label>
	 
	</div>
		
	
  <div>
                    <h2>:الون</h2>
                    </div>
                </div>

              
   <div className="  d-flex  flex-nowrap  ">
    <div className="my-2">
                  
                    </div>
    <div className="rating">
  <input className="input1" type="radio" id="star5" name="rating" value="5" />
  <label className="star" for="star5" title="Awesome" aria-hidden="true"></label>
  <input className="input1" type="radio" id="star4" name="rating" value="4" />
  <label className="star" for="star4" title="Great" aria-hidden="true"></label>
  <input className="input1" type="radio" id="star3" name="rating" value="3" />
  <label className="star" for="star3" title="Very good" aria-hidden="true"></label>
  <input className="input1" type="radio" id="star2" name="rating" value="2" />
  <label className="star" for="star2" title="Good" aria-hidden="true"></label>
  <input className="input1" type="radio" id="star1" name="rating" value="1" />
  <label className="star" for="star1" title="Bad" aria-hidden="true"></label>
 </div>  <h2>:التقييم</h2>
  </div> 


    <div className="price-add-to cart " >
    
  <div className="d-black w-100" >

        <h3 className="display-6  my-4">
        <del >${pro.price+100}</del>  ${pro.price}</h3>
        <p className="lead"></p>
         
     <button  type="submit" className="CartBtn2 "   onClick={() => addProduct(pro.it)}>
  <span className="IconContainer1"> 
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="white" ><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path></svg>
  </span>
  <p className="text1">Add to Cart</p>
  
</button>
</div>  
</div>  

      </div>
      </form>

    
    </div>
    


  );
}



  const ShowProduct = () => {
  
    return (
    <>
    {
    
    <Items it={product} src={product.image} id={product.id} price={product.price} />

  }
    </>
      
       
        );
      
    
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="d-flex">
            <div className="">
              <Skeleton height={300} width={250} />
            </div>
            <div className="">
              <Skeleton height={300} width={250} />
            </div>
            <div className="">
              <Skeleton height={300} width={250} />
            </div>
            <div className="">
              <Skeleton height={300} width={250} />
            </div>
          </div>
        </div>
      </>
    );
  };



  
  const Slick = () => {

    const sliderRef = useRef(null);

    const settings = {
     

  
      speed: 300,
      slidesToShow: 4.5,
     slidesToScroll: 2,
      initialSlide: 1,
      infinite: false,
      arrows: false,
      pauseOnHover: true,
      dots: false,
      
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3.3,
              
                slidesToScroll: 3,
            
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3.0,
                slidesToScroll: 3,
              
          
              },
            },
            {
              breakpoint:700,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
                dots: true,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
              
              },
            },
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              
              },
            },
            {
              breakpoint: 350,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
            
                 
              },
            },
          ],
        
        };
      
    
      return(
    
      <div className="mt-4" style={{position:"relative",height:"550px", overflowY:"hidden"}}>
        <div className="App mb-4" style={{position:"relative",width:"100%",}}>
          <h6></h6>
       <div className="d-flex justify-content-between " style={{width:"100px" ,position:"relative",zIndex:"1",top:"30%"}} >
        <div id="bottone5" onClick={()=>{
           sliderRef.current.slickPrev();
        }} style={{cursor:"pointer"}} ><FcNext style={{width:"25",height:"25"}} /></div>
        <div ></div>
        <div ></div>
        <div id="bottone5"  onClick={()=>{
           sliderRef.current.slickNext();
        }}  style={{cursor:"pointer"}}><FcPrevious style={{width:"25",height:"25"}}/></div>
        </div>
        </div>
        <div >
      <Slider {...settings} 
        ref={sliderRef}
        
        >
         {
           similarProducts.map((product)=>(
            <Card discrp={product.title} name={product.category} url={product.image} pr={product} myid={product.id} type={product.price}/> 
           ))
         }

          </Slider>
          </div>
          </div>
        
          );

  } 

  return (
    <>
      <Navbar />
       <div className="container " style={{marginTop:"130px"}}> 
      
          {loading ? <Loading /> : <ShowProduct />}
        
      
      </div> 
  <Slick />
      <Footer />
    </>
  );
};

export default Product;
