import React from 'react'

import { Footer, Navbar, Product } from "../components"
import { Main } from '../components'

const Products = () => {
  return (
    <>
      
      <Navbar />
      <Main />
    
      <Product />
      
      <Footer />
    </>
  )
}

export default Products