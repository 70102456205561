import React from "react";
import { useSelector} from "react-redux";
import "./style.css"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {


  const state = useSelector((state) => state.handleCart);



  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    
  };
  return (
    <>
  <div className="topp"  ></div>
<Slider {...settings} >
<div className="banner">

  <div className="">

    <div className="slider-container has-scrollbar">
      
      <div className="slider-item">

        <img src="assets/image/banner-2.jpg" alt="women's latest fashion sale" className="banner-img"/>
    
        <div className="banner-content">

          <p className="banner-subtitle">Trending item</p>

          <h2 className="banner-title">Women's latest fashion sale</h2>

          <p className="banner-text">
            starting at &dollar; <b>20</b>.00
          </p>

          <a href="#" className="banner-btn">Shop now</a>

        </div>

      </div>
    <div className="slider-item">

<img src="assets/image/banner-2.jpg" alt="women's latest fashion sale" className="banner-img"/>
banner-content
<div className="">

  <p className="banner-subtitle">Trending item</p>

  <h2 className="banner-title">Women's latest fashion sale</h2>

  <p className="banner-text">
    starting at &dollar; <b>20</b>.00
  </p>

  <a href="#" className="banner-btn">Shop now</a>

</div>

</div>
  
    </div>

  </div>

</div>
<div className="banner" >

  <div className="">

    <div className="slider-container has-scrollbar">
      
      <div className="slider-item">

        <img src="assets/image/banner-2.jpg" alt="women's latest fashion sale" className="banner-img"/>
    
        <div className="banner-content">

          <p className="banner-subtitle">Trending item</p>

          <h2 className="banner-title">Women's latest fashion sale</h2>

          <p className="banner-text">
            starting at &dollar; <b>20</b>.00
          </p>

          <a href="#" className="banner-btn">Shop now</a>

        </div>

      </div>
      <div className="slider-item">

<img src="assets/image/banner-2.jpg" alt="women's latest fashion sale" className="banner-img"/>

<div className="banner-content">

  <p className="banner-subtitle">Trending item</p>

  <h2 className="banner-title">Women's latest fashion sale</h2>

  <p className="banner-text">
    starting at &dollar; <b>20</b>.00
  </p>

  <a href="#" className="banner-btn">Shop now</a>

</div>

</div>
  
    </div>

  </div>

</div>




  </Slider>
   
    </>
  );
};

export default Home;
