import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../components";
import{ GoPersonFill} from "react-icons/go";


const Creatacunt = (pr) => {
  return (
    <>
      <Navbar />
      <div className="container " style={{marginTop:"100px"}}>
        <div className="container">
          
      
          <div className="row">
            <div className="d-flex justify-content-center ">
              <div className=" m-4 rounded-circle bg-light border border-dark">
          <GoPersonFill className='cursor-pointer'  style={{width:"100",height:"85",}}/>
          </div>
          </div>
            <div className="col-md-12 py-5 bg-light text-center rounded-3 shadow-lg p-4 mb-4 bg-white">
              
              <Link to="/Login" className="btn  btn-outline-dark mx-4">
              <h6 className="p-3 display-5">انشاء حساب</h6>
              </Link>
              <Link to="/Login" className="btn  btn-outline-dark mx-4  my-4">
              <h6 className="p-3 display-5">تسجيل دخول</h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};




export default Creatacunt;
