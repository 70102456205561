import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addCart } from "../redux/action";
import { Link } from "react-router-dom";


const Card=(pr)=>{
  const [valsize,setvalsize]=useState(null);
  const[valcolor,setvalclor]=useState(null);

const dispatch = useDispatch();

const addProduct = (product) => {
  if(valsize!==null&&valcolor!=null){ 
  dispatch(addCart(product))}
}
const handleSubmit=(event)=>{
  event.preventDefault();
console.log(valcolor)
console.log(valsize)
}
const Heart=()=>{
  return(
<label class="containe">
  <input type="checkbox"/>
  <svg id="Layer_1" version="1.0" viewBox="0 0 24 24" ><path d="M16.4,4C14.6,4,13,4.9,12,6.3C11,4.9,9.4,4,7.6,4C4.5,4,2,6.5,2,9.6C2,14,12,22,12,22s10-8,10-12.4C22,6.5,19.5,4,16.4,4z"></path></svg>
</label>
  );

}

  return(

    <>



        <div className="card new d-flex justify-content-between" id={pr.cl} style={{marginBottom:"20px"}}>
        <Heart/>
          <div>
      
            <div className="imgBx my-4 ">
              <Link  to={"/product/" + pr.myid}  >
                <img  src={pr.url}   alt="nike-air-shoe" /></Link>
            </div>
    
            
            </div>
          
            <div className="contentBx" >
                   <div className="dishid " style={{height:"240px"}}>
                   <div >
                <p  className="price ">
               <del> ${pr.type+100}</del>  ${pr.type}  </p>
                </div>
              
              <div   > <p style={{position:"relative",height:"55px",overflowY:"hidden" ,fontSize:"small"}}> {pr.discrp}</p></div> 
                </div>
                <div className="rrr">
                <form onSubmit={handleSubmit}  >    
                
                <div className="size">
                  
                    <h3>Size :</h3>
                  
                    <div className="d-flex">
          <label>
			<input type="radio" name="radios" value="option1" onChange={(event)=>{setvalsize(event.target.value)}}  />
      <span>L</span>
		</label>
                    
    <label>
			<input type="radio" name="radios" value="option2"  onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>X</span>
		</label>
    <label>
			<input type="radio" name="radios" value="option1" onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>XL</span>
		</label>
    <label>
			<input type="radio" name="radios" value="option1"  onChange={(event)=>{setvalsize(event.target.value)}} required/>
      <span>XX</span>
		</label>
    </div>
                </div>

                <div className="color justify-content-between w-100 my-2">

                    <h3>Color :</h3>
              <div className="">
               
		<label>
			<input type="radio" name="radio"  required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"black"}}></span>
		</label>
		<label>
			<input type="radio" name="radio" required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"red"}}></span>
		</label>
		<label>
			<input type="radio" name="radio" required onChange={(event)=>{setvalclor(event.target.value)}}/>
			<span style={{background:"white"}}></span>
		</label>
	 
	</div>
		

    <a href="#">   <div className="d-black w-100 "  >


 
<button className="CartBtn1" onClick={() => addProduct(pr.pr)}  >
<span  > 
<svg  height="1em" viewBox="0 0 576 512" fill="white" className="cart"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path></svg>
</span>


</button>
</div></a></div>

          
          
</form>
            </div></div>
        
        </div>























    </>
  );
}
export default Card;