import React from 'react'
import {FcPrevious,FcNext} from 'react-icons/fc'
import { useRef ,} from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BsHandbagFill,BsTextLeft} from 'react-icons/bs'
import {AiFillHeart,AiFillHome,AiTwotoneSkin} from 'react-icons/ai';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Muna from './muna'
import Slider from 'react-slick';
import{FiSearch} from 'react-icons/fi';
import './stye.scss'
 
const Navbar=()=>{
  const state = useSelector(state => state.handleCart)
 const Nav_icon=()=>{


   return(
   
   

         
               
    <>           
         
         
    
         <div>
           
         <div className="input-containerf  ">
       <input type="text" name="text" class="inputf" placeholder="Search something..."/>
     < div  className="iconf d-flex justify-content-center rounded-3" style={{alignItems:"center"}}>
       <FiSearch className='rounded-3' style={{ color:"balck", height:"30",width:"30"}}/>
       </div>
   </div>
           </div>
             <div  id="navbarSupportedContent m-4">
                      
                      <div className=" text-center Nav_icon">
   
                        <NavLink to="/cart" ><i className="fa fa-cart-shopping " style={{marginLeft:"20px",flexShrink:"0"  }}>      <span className="badge  badge-notification bg-primary opacity-50">  {state.length}</span>    <BsHandbagFill style={{width:"27",height:"27" ,color:"black"}}  /> </i>   </NavLink>
                     </div>
                 </div>
   
    
   
       
         <NavLink className="  Nav_icon  rounded-circle border border-secondary" for="radio-2" to={"/Checkout"} style={{marginLeft:"20px" ,width:"27px",height:"27px"}}>  <span><AiFillHeart className='cursor-pointer  justify-content-center'style={{width:"25",height:"25" ,color:"red"}} />
       </span>
       </NavLink>
       <NavLink className="  Nav_icon Nav_icon-img" for="radio-2" to={"/Creatacunt"} style={{marginLeft:"20px"}}>  <span>
       <img src="../assets/image/men-02.jpg" class="rounded-circle" alt="Cinque Terre" width={30} />
       </span>
       </NavLink>
   
         
           <div className='BsTextLeft'   data-bs-toggle="offcanvas" data-bs-target="#demo"  style={{marginLeft:"10px"}}> <BsTextLeft  style={{width:"25",height:"25",}} /></div>
    
       
       
      </>

   );
 }



const Navtop = () => {

  
    return (
      
        <>
        <nav className="boxShadow navbar navbar-expand-lg my-nav  rounded-3"  style={{width:"100%" ,position:"fixed",zIndex:"100" ,height:"95px",top:"0"}}>

        <div className='d-flex justify-content-between w-100 ' >
        <div >
          <img src="../assets/image/161-1619330_logos-e-commerce-png-transparent-png.png" width={90} alt="" style={{position:"relative",top:"-20px"}}/>
         </div>
  
        
         <div  className=" d-flex flex-nowrap   "style={{ zIndex:"100",position:"relative"}}

>

 
     <Nav_icon />
     </div>
     </div>
    
      
      <Navitem />
      </nav>  
      </>
    )
}


function Navitem(){
  const sliderRef = useRef(null);
  const settings = {
   

  rtl: true,
  infinite: false,
  arrows: false,

  slidesToShow: 10,
  initialSlide:3,
   
  slidesToScroll: 10,
  swipeToSlide: false,
  mobileFirst:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          
          slidesToScroll: 8,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
      
         
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        
        
        },
      },
    ],
  };

  return(
<>


 <nav className="nav-tabs" style={{width:"95%", position:"fixed",top:"7px",right:"2%",zIndex:"1"}} id="cards-container" > 
   

        
     <div className="d-flex justify-content-between mt-4"  >
      {/* <div id="bottone5" onClick={()=>{
         sliderRef.current.slickPrev();
      }} style={{cursor:"pointer" ,position:"relative",top:"23px" ,zIndex:"1"}} >< FcPrevious style={{width:"25",height:"25",zIndex:"1"}} /></div>
      <div ></div>
      <div ></div> */}
       <div id="bottone5"  onClick={()=>{
         sliderRef.current.slickNext();
      }}  style={{cursor:"pointer",position:"relative",top:"27px" ,zIndex:"1",right:"-30px"}}><FcNext style={{width:"25",height:"25" ,zIndex:"1"}}/></div>
      </div> 
    

      <Slider {...settings}
      ref={sliderRef}>
      
  
    <div className=" butn1 shadow-sm  text-center nav-item ">

          <span>أكـــسسوارات</span>

    
    </div>
    <div className=" butn1 shadow-sm  text-center nav-item">
    
          <span>عــطــــور</span>

  
    </div>
    
    <div className=" butn1 shadow-sm  text-center nav-item">
    
          <span>ســـاعــــات</span>
    
    </div>
    
    <div className=" butn1 shadow-sm  text-center nav-item">
     
          <span>عــطــــور</span>


    </div>
    <div className=" butn1 shadow-sm  text-center nav-item">

          <span>  عروض حديثة</span>

    </div>
    <div className=" butn1 shadow-sm  text-center nav-item  ">
     
          <span>ســـاعــــات</span>
  
    </div>
    <div className="  butn1 shadow-sm  text-center nav-item ">
    
     <span >هــــدايــاء</span>
  
    </div>
    <div className=" butn1 shadow-sm  text-center nav-item">
  
     <span   classname="span">تجميل</span>
 
   </div>
  
   <div className=" butn1 shadow-sm  text-center ">
    
   <span>  رجالي</span>
   
   </div>
   <div className=" butn1 shadow-sm  text-center nav-item dropdown">
   
   <span >نسائي</span>
       
 
   </div>
   <div className=" butn1 shadow-sm  text-center nav-item">
    
         <span>اطفال</span>
 
   </div>
   
   <div className=" butn1 shadow-sm nav-item "   data-bs-toggle="offcanvas" data-bs-target="#demo"  >
    
    <span  href="#v"  >الكل</span>
 
   </div> 
        
      </Slider>
      </nav> 
</>

  )
}


function Navbot(){
  return(
   <div className="containerbotoom " >
	 <div className="tabs d-flex flex-nowrap rounded-3 shadow-lg  bg-white " style={{width:"98%",bottom:"4px" ,position:"fixed",left:"1%" ,zIndex:"100"}}>
		
    <NavLink className="tab d-flex container-fluid" for="radio-2" to={"/Creatacunt"} >  <AiFillHome className='cursor-pointer  justify-content-center' style={{width:"25",height:"25"}}/>
    <span className="glider"></span>
    <input type="radio" id="radio-1" name="tabs"  />
    </NavLink>
		<input type="radio" id="radio-2" name="tabs" />
    <NavLink className="tab d-flex container-fluid" for="radio-2" to={"/"} >  
      <AiFillHeart className='cursor-pointer  justify-content-center'style={{width:"25",height:"25",color:"red",opacity:"0.8"}}  />
    
    </NavLink>

		<NavLink className="tab justify-content-center container-fluid" for="radio-2" to="/cart" style={{flexGrow:"2"}}>
    <input type="radio" id="radio-3" name="tabs" />
  <i className="fa fa-cart-shopping d-flex flex-nowrap " style={{marginLeft:"30px" }}>      <span className="badge  badge-notification bg-primary opacity-50 ">  {state.length}</span>    <BsHandbagFill style={{width:"27",height:"27" ,color:"black"}}  /> </i>   
  
    </NavLink>
 

    {/* <NavLink className="tab justify-content-center container-fluid" for="radio-2" to={"/Search"} >  
    <FiSearch className='cursor-pointer  justify-content-center' style={{width:"20",height:"25" ,color:"black"}}/>
    <input type="radio" id="radio-4"name="tabs" />
  
    </NavLink> */}
	
     
    
	
    <NavLink className="tab justify-content-center container-fluid" for="radio-2" to={"/Creatacunt"} >  
       <span>
       <img src="../assets/image/men-02.jpg" className="rounded-circle justify-content-center" alt="Cinque Terre" width={30} />
       </span>
      
   	<input type="radio" id="radio-5" name="tabs" />
   </NavLink>
	
		
	</div>
</div>
 
  
 
  );
}



    return(
        <>
        <Navtop />
        <Muna />
        <div className='Navbot'>
      <Navbot />
      </div>
        </>
    );

}
export default Navbar
