import React from "react";
import {  Navbar } from "../components"

const Checkout = () => {
  
  
  return (
    <>
      <Navbar />
    </>
  );
};

export default Checkout;
