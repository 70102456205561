import React, { useState, useEffect ,useRef} from "react";
import Card from "./card";
import axios from "axios";
import Slider from 'react-slick';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {FcPrevious,FcNext} from 'react-icons/fc'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";





const Products = () => {
  const [data, setData] = useState([]);
  const [discount_hid,setdiscount_hid]=useState(false);

  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);

  const CountdownTimer = ({ targetDate }) => {
    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(getTimeRemaining());
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, []);
  
    function getTimeRemaining() {
      const total = Date.parse(targetDate) - Date.parse(new Date());
      if(total>0){
       setdiscount_hid(true);
      }
      
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      // const years = Math.floor(days / 365);
      // const months = Math.floor((days % 365) / 30);
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
        // months,
        // years
      };
    }
  
    return (
    
  
  
  
      <div className="d-flex justify-content-between" style={{width:"100%" ,position:"relative",zIndex:"1"}}>
         {/* <div >{timeRemaining.years} </div>
      <div > /{timeRemaining.months} </div> */}
        {/* <div  > <p className="mx-2 my-4">تنتهي في</p></div>  */}
      <div className="w-100 mx-2 my-4" > <p className="textdis"  >عروض مفاجأة
  </p></div> 
        <div className="w-100  my-4 ">
        <div className="w-100 " style={{ top:"0px" ,position:"relative",left:"0px"}}>
          <button className="TimeBtn mx-1" > {timeRemaining.days%12 }  </button>  
          <button className="TimeBtn" >{timeRemaining.hours} </button>
          <button  className="TimeBtn">{timeRemaining.minutes } </button>
          <button  className="TimeBtn">{timeRemaining.seconds} </button>
  
    
      
      
  </div></div>
      </div>
  
    );
  };
  
  const filterProduct =  (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  
  }
  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={200} />
        </div>
      </>
    );
  };


  
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await axios.create({
        withCredentials: true,
      });
      response.get("../assets/ajax.php?show")
      .then((resp) =>  {
      
        setData( resp.data);
        setFilter( resp.data.filter((item) => item.category === "women's clothing"))
        setLoading(false);
      
      })

      
      

    };

    getProducts();
    
  
    
  }, []);

  
const Shoping_Item=()=>{
  const settings = {
      speed: 300,
    slidesToShow: 6.5,
   slidesToScroll: 6,
    initialSlide: 1,
    infinite: false,
    rows:2,
    arrows: false,
    pauseOnHover: true,
    dots: true,
    
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6.3,
             
              slidesToScroll: 3,
          
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 5.0,
              slidesToScroll: 3,
            
        
            },
          },
          {
            breakpoint:800,
            settings: {
              slidesToShow: 4.0,
              slidesToScroll: 4,
              dots: true,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 3,
            
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            
            },
          },
          {
            breakpoint: 350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          
               
            },
          },
        ],
        appendDots: dots => (
          <div
          className=""
          >
            <ul className="shadow-none my-4  mb-4 bg-light"> {dots} </ul>
          </div>
        ),
      };
    
  return(
    <> 

    
<Slider {...settings} >
      
    {

  filter.map((product) => (
        
    
     <div className=" w-100   my-1" >
    
    <div className=" navlink  d-flex " >
      
      <Link to={"/product/" + product.category} className="border border-3 rounded-circle overflow-hidden mb-4 bg-white d-flex " style={{width:"40%",height:"40%", justifyContent:"center"}}>
      <img src={product.image }class="rounded " alt="Cinque Terre" height="100%" />
      </Link>
      <div className="mx-2" >
      <p className="text-center  "style={{textDecoration: "none",color:"black"  ,textAlign:"center",fontSize:"small"  }}>{product.category}</p>
      </div>
      </div >

  
      </div>  ))}
      </Slider>
    
    </>
  );
}

  function Navitem(){

  
  const sliderRef = useRef(null);

  const settings = {
     

  
    
      speed: 300,
    slidesToShow: 8,
   slidesToScroll: 4,
    initialSlide: 1,
    infinite: false,
    arrows: false,
    pauseOnHover: true,
    dots: false,
    
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3.3,
            
              slidesToScroll: 3,
          
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 5.0,
              slidesToScroll: 3,
            
        
            },
          },
          {
            breakpoint:700,
            settings: {
              slidesToShow: 4.5,
              slidesToScroll: 4.5,
              dots: true,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 3.5,
            
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2.5,
            
            },
          },
          {
            breakpoint: 350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          
               
            },
          },
        ],
        appendDots: dots => (
          <div
          className=""
          >
            <ul className="shadow-none my-4  mb-4 bg-light"> {dots} </ul>
          </div>
        ),
      };
    
  
    return(
      <>
          <div className="w-100  my-1" >
           <p className="textdis mx-2"  >           جميع الاقسام

</p></div>
  
      <div className="App mb-4" style={{position:"relative",width:"100%",}}>
        <h6></h6>
     <div className="d-flex justify-content-between mt-4" style={{width:"100px" ,position:"relative",zIndex:"1",top:"100%"}} >
      <div id="bottone5" onClick={()=>{
         sliderRef.current.slickPrev();
      }} style={{cursor:"pointer"}} ><FcNext style={{width:"25",height:"25"}} /></div>
      <div ></div>
      <div ></div>
      <div id="bottone5"  onClick={()=>{
         sliderRef.current.slickNext();
      }}  style={{cursor:"pointer"}}><FcPrevious style={{width:"25",height:"25"}}/></div>
      </div>
      </div>
    <Slider {...settings} 
      ref={sliderRef}
      
      >
        
        
         <div>
        <div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct("men's clothing")}>
    <img src="./assets/image/men-02.jpg" class="rounded " alt="Cinque Terre" width="auto" height="100%" />

        <span></span>
        <div className="card-sec">
        <p className="text-center my-2">تسوق للرجالي</p>    </div>
    </div>
    </div>
    
</div>


<div class="container1 ">
    <div class="card_box1"   onClick={() => filterProduct(data)}>
    <img src="assets/image/kntosa.com_07_21_16230761071713.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
      
        <span></span>
        <div className="card-sec">
        <p className="text-center my-2">تسوق للرجالي</p> </div>
    </div>

</div>

<div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct("women's clothing")}>
    <img src="assets/image/71825708000000_original.jpg" class="rounded " alt="Cinque Terre" width="auto" height="100%" />
        <span></span>
        <div className="card-sec">
    <p className="text-center my-2">تسوق للنساء</p>
    </div>
    </div>
    
</div>

<div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct(data)}>
    <img src="assets/image/happy-little-boy-holding-shopping-260nw-132980525.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
        <span></span>
        <div className="card-sec">
          <p className="text-center my-2">تسوق للولادي</p> </div>
    </div>
  
</div>
<div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct(data)}>
    <img src="assets/image/29370964-little-girl-with-a-big-package-went-shopping-in-the-store.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
        <span></span>
        <div className="card-sec">
      <p className="text-center my-2">تسوق للبناتي</p>     </div>
    </div>
  
</div>
<div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct(data)}>
    <img src="assets/image/depositphotos_64589627-stock-photo-happy-baby-shopping-isolated-on.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
        <span></span>
        <div className="card-sec">
        <p className="text-center my-2"> تسوق مواليد</p>     </div>
    </div>

</div>
<div class="container1 ">
    <div class="card_box1"  onClick={() => filterProduct(data)}>
    <img src="assets/image/images.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
        <span></span>
        <div className="card-sec">
        <p className="text-center my-2">تسوق للمنزل</p>   </div>
    </div>
  
</div>
<div class="container1 ">
    <div class="card_box1"  onClick={() => setFilter(data)}>
    <img src="assets/image/cosmetic-products.jpg" class="rounded " alt="Cinque Terre" width="100%" height="100%" />
        <span></span>
        <div className="card-sec">
        <p className="text-center my-2">تسوق للتجميل</p>   </div>
    </div>
  
</div>
              
  
   </Slider>

 <div className="rounded border border-1 bg-light h-25" style={{overflow:"hidden"}} >
 

 
   <Shoping_Item />

   
   </div>
   <hr />
   </>
  );}  
  
  const Showdiscount = () => {

    const sliderRef = useRef(null);

const targetDate = new Date('2023-12-31T18:59:00');
const settings = {
     

  
    speed: 300,
  slidesToShow: 4.5,
 slidesToScroll: 4,
  initialSlide: 1,
  infinite: false,
  arrows: false,
  pauseOnHover: true,
  dots: false,
  
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3.3,
          
            slidesToScroll: 3,
        
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3.0,
            slidesToScroll: 3,
          
      
          },
        },
        {
          breakpoint:700,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
            dots: true,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
          
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          
          },
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        
             
          },
        },
      ],
      appendDots: dots => (
        <div
        className=""
        >
          <ul className="shadow-none my-4  mb-4 bg-light"> {dots} </ul>
        </div>
      ),
    };
   if(Date.parse(targetDate) - Date.parse(new Date())>0){
    
      return(
    <>
      
         
        <div className="discount-time  bg-light"style={{height:"37%"}}>
          <CountdownTimer targetDate={targetDate}/>
          <div className=" App">
        <div className=" d-flex justify-content-between" style={{width:"100px" ,position:"relative",zIndex:"1",top:"0px", height:"30px"}} >
        <div id="bottone5" onClick={()=>{
           sliderRef.current.slickPrev();
        }} style={{cursor:"pointer"}} ><FcNext style={{width:"25",height:"25"}} /></div>
        <div ></div>
        <div ></div>
        <div id="bottone5"  onClick={()=>{
           sliderRef.current.slickNext();
        }}  style={{cursor:"pointer"}}><FcPrevious style={{width:"25",height:"25"}}/></div>
        </div></div>
      <Slider {...settings} 
        ref={sliderRef}
        
        >
         {
           data.map((pr)=>(
            <div className="" style={{position:"relative"}}>
            <div className="card9 new-vew bg-white">
            <img src={pr.image} alt="Denim Jeans" />
            <span className="span-new-vew "></span>
            <div className="my-4 w-100 container">
              <div className="title-card">
            <p >{pr.title}</p>
            </div>
            <p className="price">
            <del  >${(pr.price)}</del>  ${pr.price}</p>
            </div>
          
          </div>
          </div>

           ))
         }

          </Slider>
          </div>
          
          </>
          );}else{
            return(
              <>
              </>
            );
          }

  };



  const ShowProducts = () => {

    const sliderRef = useRef(null);

  const settings = {
     

  
      speed: 300,
    slidesToShow: 4.5,
   slidesToScroll: 4,
    initialSlide: 1,
    infinite: false,
    arrows: false,
    pauseOnHover: true,
    dots: false,
    
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3.3,
            
              slidesToScroll: 3,
          
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3.0,
              slidesToScroll: 3,
            
        
            },
          },
          {
            breakpoint:700,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
              dots: true,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
            
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            
            },
          },
          {
            breakpoint: 350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          
               
            },
          },
        ],
        appendDots: dots => (
          <div
          className=""
          >
            <ul className="shadow-none my-4  mb-4 bg-light"> {dots} </ul>
          </div>
        ),
      };
    
      return(
    
      
      <div className=" w-100 bg-light my-4"  style={{position:"relative",height:"3%", overflowY:"hidden"}}>
            <div className="w-100  my-1" >
           <p className="textdis mx-2"  >           احدث الموديلات

</p></div>
        <div className="App mb-4" style={{position:"relative",width:"100%",}}>
  
       <div className="d-flex justify-content-between " style={{width:"100px" ,position:"relative",zIndex:"1",top:"30%"}} >
        <div id="bottone5" onClick={()=>{
           sliderRef.current.slickPrev();
        }} style={{cursor:"pointer"}} ><FcNext style={{width:"25",height:"25"}} /></div>
        <div ></div>
        <div ></div>
        <div id="bottone5"  onClick={()=>{
           sliderRef.current.slickNext();
        }}  style={{cursor:"pointer"}}><FcPrevious style={{width:"25",height:"25"}}/></div>
        </div>
        </div>
        <div >
      <Slider {...settings} 
        ref={sliderRef}
        
        >
         {
           data.map((product)=>(
            <Card  discrp={product.title} name={product.category} url={product.image} pr={product} myid={product.id} type={product.price}/> 
           ))
         }

          </Slider>
          </div>
          </div>
        
          );

  } 
    const NewProd=()=>{
      return(
        <div className="my-8">
          <h2>الواصل حديثاً</h2> 
          <div className="d-flex justify-content-center flex-wrap ">
          {
           data.map((product)=>(
            <Card cl={"w-48"} discrp={product.title} name={product.category} url={product.image} pr={product} myid={product.id} type={product.price}/> 
           ))
         }

          </div>


        </div>
      );
    }
  return (
    <>

    <div className="w-100">
      
        
        
        <div className="justify-content-center w-100">
        
          {loading ? <Loading /> : <Navitem />      }

       <div className="my-3 w-100">
   <img className="alan" src="assets/image/aa.jpg"/>
       </div>
          {loading ? <Loading /> : <Showdiscount /> }
        
          {loading ? <Loading /> : <ShowProducts /> }
         <div className="mb-5">
         <img className="alan" src="assets/image/1696837506ff3ce61f9bbb74c85813133d14feca7a_thumbnail_2000x.webp"/>
         </div>
        </div>
        {loading ? <Loading /> : <NewProd /> }
    
      </div>

    
    </>
  );
};

export default Products;
