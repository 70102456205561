import React from "react";

const Footer = () => {
  return (
    <>
    <footer>

<div class="footer-category" >

  <div class="container">

    <h2 class="footer-category-title">Brand directory</h2>

    <div class="footer-category-box">

      <h3 class="category-box-title">Fashion :</h3>

      <a href="#" class="footer-category-link">T-shirt</a>
      <a href="#" class="footer-category-link">Shirts</a>
      <a href="#" class="footer-category-link">shorts & jeans</a>
      <a href="#" class="footer-category-link">jacket</a>
      <a href="#" class="footer-category-link">dress & frock</a>
      <a href="#" class="footer-category-link">innerwear</a>
      <a href="#" class="footer-category-link">hosiery</a>

    </div>

    <div class="footer-category-box">
      <h3 class="category-box-title">footwear :</h3>
    
      <a href="#" class="footer-category-link">sport</a>
      <a href="#" class="footer-category-link">formal</a>
      <a href="#" class="footer-category-link">Boots</a>
      <a href="#" class="footer-category-link">casual</a>
      <a href="#" class="footer-category-link">cowboy shoes</a>
      <a href="#" class="footer-category-link">safety shoes</a>
      <a href="#" class="footer-category-link">Party wear shoes</a>
      <a href="#" class="footer-category-link">Branded</a>
      <a href="#" class="footer-category-link">Firstcopy</a>
      <a href="#" class="footer-category-link">Long shoes</a>
    </div>

    <div class="footer-category-box">
      <h3 class="category-box-title">jewellery :</h3>
    
      <a href="#" class="footer-category-link">Necklace</a>
      <a href="#" class="footer-category-link">Earrings</a>
      <a href="#" class="footer-category-link">Couple rings</a>
      <a href="#" class="footer-category-link">Pendants</a>
      <a href="#" class="footer-category-link">Crystal</a>
      <a href="#" class="footer-category-link">Bangles</a>
      <a href="#" class="footer-category-link">bracelets</a>
      <a href="#" class="footer-category-link">nosepin</a>
      <a href="#" class="footer-category-link">chain</a>
      <a href="#" class="footer-category-link">Earrings</a>
      <a href="#" class="footer-category-link">Couple rings</a>
    </div>

    <div class="footer-category-box">
      <h3 class="category-box-title">cosmetics :</h3>
    
      <a href="#" class="footer-category-link">Shampoo</a>
      <a href="#" class="footer-category-link">Bodywash</a>
      <a href="#" class="footer-category-link">Facewash</a>
      <a href="#" class="footer-category-link">makeup kit</a>
      <a href="#" class="footer-category-link">liner</a>
      <a href="#" class="footer-category-link">lipstick</a>
      <a href="#" class="footer-category-link">prefume</a>
      <a href="#" class="footer-category-link">Body soap</a>
      <a href="#" class="footer-category-link">scrub</a>
      <a href="#" class="footer-category-link">hair gel</a>
      <a href="#" class="footer-category-link">hair colors</a>
      <a href="#" class="footer-category-link">hair dye</a>
      <a href="#" class="footer-category-link">sunscreen</a>
      <a href="#" class="footer-category-link">skin loson</a>
      <a href="#" class="footer-category-link">liner</a>
      <a href="#" class="footer-category-link">lipstick</a>
    </div>

  </div>

</div>

<div class="footer-nav">

  <div class="container">

    <ul class="footer-nav-list">

      <li class="footer-nav-item">
        <h2 class="nav-title">Popular Categories</h2>
      </li>

      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Fashion</a>
      </li>

      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Electronic</a>
      </li>

      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Cosmetic</a>
      </li>

      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Health</a>
      </li>

      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Watches</a>
      </li>

    </ul>

    <ul class="footer-nav-list">
    
      <li class="footer-nav-item">
        <h2 class="nav-title">Products</h2>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Prices drop</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">New products</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Best sales</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Contact us</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Sitemap</a>
      </li>
    
    </ul>

    <ul class="footer-nav-list">
    
      <li class="footer-nav-item">
        <h2 class="nav-title">Our Company</h2>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Delivery</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Legal Notice</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Terms and conditions</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">About us</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Secure payment</a>
      </li>
    
    </ul>

    <ul class="footer-nav-list">
    
      <li class="footer-nav-item">
        <h2 class="nav-title">Services</h2>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Prices drop</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">New products</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Best sales</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Contact us</a>
      </li>
    
      <li class="footer-nav-item">
        <a href="#" class="footer-nav-link">Sitemap</a>
      </li>
    
    </ul>

    <ul class="footer-nav-list">

      <li class="footer-nav-item">
        <h2 class="nav-title">Contact</h2>
      </li>

      <li class="footer-nav-item flex">
        <div class="icon-box">
          <ion-icon name="location-outline"></ion-icon>
        </div>

        <address class="content">
          419 State 414 Rte
          Beaver Dams, New York(NY), 14812, USA
        </address>
      </li>

      <li class="footer-nav-item flex">
        <div class="icon-box">
          <ion-icon name="call-outline"></ion-icon>
        </div>

        <a href="tel:+607936-8058" class="footer-nav-link">(607) 936-8058</a>
      </li>

      <li class="footer-nav-item flex">
        <div class="icon-box">
          <ion-icon name="mail-outline"></ion-icon>
        </div>

        <a href="mailto:example@gmail.com" class="footer-nav-link">example@gmail.com</a>
      </li>

    </ul>

    <ul class="footer-nav-list">

      <li class="footer-nav-item">
        <h2 class="nav-title">Follow Us</h2>
      </li>

      <li>
        <ul class="social-link">

          <li class="footer-nav-item">
            <a href="#" class="footer-nav-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li class="footer-nav-item">
            <a href="#" class="footer-nav-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>

          <li class="footer-nav-item">
            <a href="#" class="footer-nav-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

          <li class="footer-nav-item">
            <a href="#" class="footer-nav-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

        </ul>
      </li>

    </ul>

  </div>

</div>

<div class="footer-bottom">

  <div class="container">

    <img src="./assets/images/payment.png" alt="payment method" class="payment-img"/>

    <p class="copyright">
      Copyright &copy; <a href="#">Anon</a> all rights reserved.
    </p>

  </div>

</div>

</footer>

    </>
  );
};

export default Footer;
