import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import React from "react";





export default function Muna(){

const filterProduct =  (cat) => {
  const updatedList = data.filter((item) => item.category === cat);
  setFilter(updatedList);

}


function UlR(pro)
{

  return(
    <div className="navlink  d-flex"  style={{justifyContent:"center",cursor:"pointer",textAlign:"center"}} >
    
 <div className="border border-3 rounded-circle overflow-hidden mb-4 bg-white d-flex" style={{width:"50px",height:"50px", justifyContent:"center"}}>

   <img src={pro.image }class="rounded " alt="Cinque Terre" height="100%" />

   </div>
   
   <p className="text-center"style={{textDecoration: "none",color:"black" ,fontSize:"10px" ,textAlign:"center"  }}>{pro.name }</p>
  
   </div>

  );

}
const Shoping_Item=(product)=>{
 
  return(
    <> 
  
  <Link className="    my-1" to={"/product/" + product.name} >
    
    <div className=" navlink  d-flex " >
      <div className="border border-3 rounded-circle overflow-hidden mb-4 bg-white d-flex " style={{width:"50px",height:"50px", justifyContent:"center"}}>
      <img src={product.image }class="rounded " alt="Cinque Terre" height="100%" />
      </div>
      <div className="" >
      <p className="text-center  "style={{textDecoration: "none",color:"black" ,fontSize:"10px" ,textAlign:"center"  }}>{product.name}</p>
      </div>
      </div >

  
      </Link> 
    </>
  );
}

 
const [data, setData] = useState([]);

const [filter, setFilter] = useState(data);
const [loading, setLoading] = useState(false);


     

useEffect(() => {
  const getProducts = async () => {
    setLoading(true);
    const response = await axios.create({
      withCredentials: true,
    });
    response.get("../assets/ajax.php?show")

    .then((resp) =>  {
    
      setData( resp.data);
      setFilter( resp.data.filter((item) => item.category === "women's clothing"))
      setLoading(false);
    
    })

    
    

  };

  getProducts();
  

  
}, []);












return(


        <div className="offcanvas offcanvas-start " id="demo">
      
        
        <div className=" d-flex w-100  " style={{position:"relative",width:"100%",justifyContent:"space-between"}}>
      

          <div >


          <div className="p-2 w-100  bd-highligh">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
          <h4 className="offcanvas-title text-center  ">كل الفئات</h4>
          <hr />
          </div>
   <div  className="d-flex flex-wrap w-75 ">

      {    filter.map((product) => (
        
      
   <Shoping_Item image={product.image}  name={product.category} discr={""}/>

   ))}
   </div>
   </div>
   <div className=" border border-5 shadow-sm  bg-white  h-100  bg-white rounded-3 clum-maun" >
        
        { data.map((product)=>(
          <div className="justify-content-center " style={{position:"relative",left:"0px" ,right:"10px",cursor:"pointer", marginLeft:"10px"}} onClick={()=>{filterProduct(product.category)}}>
          <UlR image={product.image}  name={product.category}   Id="ch"/>

       </div>))}
       </div >
   
       </div>  </div> );
      
       
       
 }